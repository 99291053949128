<template>
  <v-app>
    <Header/>
    <v-main>
      <transition mode="out-in">
        <router-view/>
      </transition>
    </v-main>
    <Footer/>
  </v-app>
</template>
<script>

import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'PublicLayout',
  components: {
    Header,
    Footer
  },
  computed: {
  }

}
</script>

<template>
  <v-app-bar app flat>
    <v-container class="">
      <div class="d-flex align-center">
        <router-link tag="div" class="cursor-pointer" to="/">
          <Logo/>
        </router-link>
        <Menu class="ml-3"/>
        <v-spacer/>
        <ProfileInfo class="ml-3"/>
      </div>
    </v-container>
  </v-app-bar>
</template>
<script>

import Logo from '@/components/Logo'
import Menu from "@/layouts/components/Menu"
import ProfileInfo from "@/layouts/components/ProfileInfo"

export default {
  name: 'HeaderComponent',
  components: {
    Logo,
    Menu,
    ProfileInfo
  },
  computed: {

  }
}
</script>


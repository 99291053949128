<template>
  <section v-if="user">
    <router-link to="#">
      тариф: "{{ user.tariff.name }}".
      <span v-if="isShipper && hasValidTariff"
            :class="[{'cd-primary-filled': withWarning}]"> действует до {{ formatDate(tariffValidUntil) }}</span>
      <span v-else-if="isShipper" class="cd-primary-filled"> просрочен {{ formatDate(tariffValidUntil) }}</span>
    </router-link>
  </section>
</template>
<script>

import {NOTIFICATION_OF_END_OF_TARIFF_IN_DAYS} from '@/config/params'
import moment from 'moment'

export default {
  name: 'ProfileInfoComponent',
  components: {},
  data() {
    return {}
  },
  computed: {
    tariffValidUntil() {
      return this.user.tariff.valid_until
    },
    isShipper() {
      return this.$store.getters.isShipper
    },
    user() {
      return this.$store.getters.activeUser
    },
    withWarning() {
      return moment(this.tariffValidUntil).format('X') <= moment().add(NOTIFICATION_OF_END_OF_TARIFF_IN_DAYS, 'days').format('X')
    },
    hasValidTariff() {
      return moment(this.tariffValidUntil).format('X') >= moment().format('X')
    }
  }
}
</script>

<template>
  <v-footer app class="px-3 py-1">
    <v-container>
      <hr class="horisontal-delimiter"/>
      <div class="py-3 d-flex space-between">
        <div>
          &copy; 2021 - {{ new Date().getFullYear() }} ревах групп
        </div>
        <v-spacer/>
        <div class="contacts">
          <a target="_blank" :href="phoneLink">
            {{ phone }}
          </a>
          <span class="vertical-delimiter"/>
          <a target="_blank" :href="emailLink">
            {{ email }}
          </a>
          <span class="vertical-delimiter"/>
          <a target="_blank" :href="telegramLink">
            {{ telegram }}
          </a>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>
<script>

import {formatPhone} from "@/mixins/formatPhone"

export default {
  name: 'FooterComponent',
  components: {},
  computed: {
    telegram() {
      return process.env.VUE_APP_TELEGRAM_BOT_NAME
    },
    email() {
      return process.env.VUE_APP_CONTACT_EMAIL
    },
    phone() {
      return formatPhone(process.env.VUE_APP_CONTACT_PHONE)
    },
    telegramLink() {
      return 'https://t.me/' + process.env.VUE_APP_TELEGRAM_BOT_NAME
    },
    emailLink() {
      return 'mailto:' + process.env.VUE_APP_CONTACT_EMAIL
    },
    phoneLink() {
      return 'tel:' + process.env.VUE_APP_CONTACT_PHONE
    }
  }

}
</script>

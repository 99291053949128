export function clearPhone(phone = '')
{
  if (phone) {
    phone = phone.replace(/\D+/g, '')
    phone = phone.length <= 10 ? phone : phone.substring(1)
  }
  return phone
}

export function formatPhone(phone = '') {
  if (phone) {
    phone = "" + phone
    phone = clearPhone(phone)
    return `+7 (${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 8)}-${phone.substring(8)}`
  }
  return phone
}

export default {
  methods: {
    formatPhone
  }
}

<template>
  <section class="px-3 py-1">
    <slot v-for="(item, index) in menu">
      <span v-if="$route.path === item.route" :class="[{'primary-link' : item.isMain, 'cd-menu-item__active': true}]" :key="'cd-active-menu-link'+index">
        {{ item.title }}
      </span>
      <router-link v-else :to="item.route" :class="[{'primary-link' : item.isMain}]" :key="'cd-menu-link'+index">
        {{ item.title }}
      </router-link>
      <span v-if="index + 1 !== menu.length" class="vertical-delimiter" :key="'cd-menu-vd'+index"/>
    </slot>
  </section>
</template>
<script>

export default {
  name: 'MenuComponent',
  components: {},
  data() {
    return {
      unauthenticatedMenu: [
        {
          title: "попробовать бесплатно",
          route: "/signup",
          isMain: true,
          visible: true
        },
        {
          title: "войти",
          route: "/signin",
          isMain: false,
          visible: true
        },
        {
          title: "маркетплейс площадок",
          route: "",
          isMain: false,
          visible: true
        },
      ],
    }
  },
  computed: {
    isShipper() {
      return this.$store.getters.isShipper
    },
    isCarrier() {
      return this.$store.getters.isCarrier
    },
    menu() {
      return !this.$store.getters.isAuthenticated ? this.unauthenticatedMenu :
        [
          {
            title: "площадки",
            route: "/pads",
            visible: true
          },
          {
            title: "водители",
            route: "/",
            visible: this.isCarrier
          },
          {
            title: "транспорт",
            route: "/",
            visible: this.isCarrier
          },
          {
            title: "маркетплейс",
            route: "/",
            visible: this.isShipper
          },
          {
            title: "профиль",
            route: "/",
            visible: true
          },
          {
            title: "выйти",
            route: "/logout",
            visible: true
          },
        ].filter(item => item.visible)
    }
  }
}
</script>
